






































import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'

export default mixins(Section).extend({
  name: 'HtmlText2',
  computed: {
    getImgSize () {
      if (this.isMobile) {
        return '100%'
      }

      return (this.data.configuration.wb_image_width__number__ <= 800)
        ? this.data.configuration.wb_image_width__number__ : 800
    }
  }
})
