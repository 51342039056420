var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",class:{ 'section-invert': _vm.invertColors }},[_c('v-container',{staticClass:"full-height",attrs:{"fluid":_vm.data.configuration.wb_container_fluid__checkbox__}},[_c('v-row',{staticClass:"full-height",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-md-flex text-left",class:{ 'align-center justify-center': _vm.data.configuration.wb_align_center__checkbox__},style:({ minHeight: ((_vm.data.configuration['wb_height__style_height__']) + "px") }),attrs:{"cols":_vm.data.configuration.wb_cols_cols__cols_cols__,"sm":_vm.data.configuration.wb_cols_sm__cols_sm__,"md":_vm.data.configuration.wb_cols_md__cols_md__,"lg":_vm.data.configuration.wb_cols_lg__cols_lg__,"xl":_vm.data.configuration.wb_cols_xl__cols_xl__}},[(!_vm.data.configuration.wb_image_to_right__checkbox__)?_c('v-img',{class:{
                  'mr-4': !_vm.isMobile,
               },attrs:{"src":_vm.getImageUrl('img_1'),"max-width":_vm.getImgSize}}):_vm._e(),_c('div',{class:{
          'mt-2': _vm.isMobile && !_vm.data.configuration.wb_image_to_right__checkbox__,
          'mb-2': _vm.isMobile && _vm.data.configuration.wb_image_to_right__checkbox__
        },domProps:{"innerHTML":_vm._s(_vm.data.configuration.wb_text__html_text__)}}),(_vm.data.configuration.wb_image_to_right__checkbox__)?_c('v-img',{class:{
                  'ml-4': !_vm.isMobile,
               },attrs:{"src":_vm.getImageUrl('img_1'),"max-width":_vm.getImgSize}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }